import type { NextPage } from 'next'
import { layoutTypes } from 'lib/static/globalStaticData'
import { withSessionSsr } from 'lib/withSession'

import { Login } from 'components/forms/Login'
import { Head } from 'components/organisms'

const Page: NextPage = ({ meta }: any) => {
  return (
    <div className='page'>
      <Head {...meta} />
      <section className='section container flex flex-col justify-center items-center py-[24px] sm:!py-[48px]'>
        <div className='flex shadow-sm bg-white py-[32px] px-[20px] w-full max-w-[444px] justify-center rounded-[4px]'>
          <Login hideHeader />
        </div>
      </section>
    </div>
  )
}

export const getServerSideProps = withSessionSsr(async function getServerSideProps() {
  return {
    props: {
      meta: {
        title: 'Login'
      },
      pageTitle: 'Sign in',
      layoutType: layoutTypes.minimal
    }
  }
})

export default Page
